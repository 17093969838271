define("backend/models/department", ["exports", "ember-data", "ember-cp-validations", "moment", "backend/helpers/format-currency"], function (_exports, _emberData, _emberCpValidations, _moment, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    subName: attr('string'),
    notes: attr('string'),
    isStudyPartner: attr('boolean', {
      defaultValue: false
    }),
    status: belongsTo('departmentStatus', {
      async: false
    }),
    source: attr('string'),
    // ------ NEXT STEP --------
    nextStep: attr('string'),
    // ------- DEMO SCHEDULED -----
    demoDate: attr('string'),
    // ------- TRIAL SCHEDULED ----
    trialStartDate: attr('string'),
    trialEndDate: attr('string'),
    numDevices: attr('string'),
    // ------ PROBABILITY ---------
    probability: attr('string'),
    expectedDate: attr('string'),
    expectedDevices: attr('string'),
    // --------- VALUE ------------
    plan: attr('string'),
    pricePerDevice: attr('string'),
    insurance: attr('string'),
    langs: attr('string'),
    hcs: attr('string'),
    // ---------- WON ------------
    // --------- LOST -------------
    reason: attr('string'),
    reasonAdditional: attr('string'),
    followupDate: attr('string'),
    // --------- ROUGH BUFFER IMPLEMENTATION FOR SALES PIPELINE --------
    bufferStatus: Ember.computed('status.id', function () {
      return this.get('status');
    }),
    bufferExpectedDate: Ember.computed('expectedDate', function () {
      return this.get('expectedDate');
    }),
    hasDirtyAttributesOrRelationships: Ember.computed('hasDirtyAttributes', 'bufferStatus.id', 'status.id', 'bufferExpectedDate', function () {
      return this.get('hasDirtyAttributes') || this.get('bufferStatus') !== this.get('status') || this.get('bufferExpectedDate') !== this.get('expectedDate');
    }),
    rollbackAttributesAndRelationships: function rollbackAttributesAndRelationships() {
      this.rollbackAttributes();
      this.set('bufferStatus', this.get('status'));
      this.set('bufferExpectedDate', this.get('expectedDate'));
    },
    save: function save() {
      var _this = this;

      var _this$getProperties = this.getProperties('bufferStatus', 'bufferExpectedDate', 'expectedDate', 'status'),
          bufferStatus = _this$getProperties.bufferStatus,
          bufferExpectedDate = _this$getProperties.bufferExpectedDate,
          expectedDate = _this$getProperties.expectedDate,
          status = _this$getProperties.status;

      var statusChange = this.get('bufferStatus.id') !== this.get('status.id');

      if (statusChange) {
        this.set('status', bufferStatus);
        this.set('latestStatusChange', (0, _moment.default)().format());
      }

      if (this.get('expectedDate') !== this.get('bufferExpectedDate')) {
        this.set('expectedDate', bufferExpectedDate);
      }

      return this._super.apply(this, arguments).then(function (response) {
        _this.set('bufferStatus', _this.get('status'));

        _this.set('bufferExpectedDate', _this.get('expectedDate'));

        return response;
      }).catch(function (ex) {
        _this.set('status', status);

        _this.set('expectedDate', expectedDate);

        throw ex;
      });
    },
    // ------ READONLY ------
    latestStatusChange: attr('string'),
    missingMrrFields: Ember.computed('status.id', 'expectedDevices', 'pricePerDevice', 'probability', 'expectedDate', 'insurance', 'langs', 'hcs', function () {
      if (this.get('status.isOfferPhaseDone')) {
        var _this$getProperties2 = this.getProperties('expectedDevices', 'pricePerDevice', 'insurance'),
            expectedDevices = _this$getProperties2.expectedDevices,
            pricePerDevice = _this$getProperties2.pricePerDevice,
            insurance = _this$getProperties2.insurance;

        if (!expectedDevices || !pricePerDevice || !insurance) {
          return "Cannot calculate without: Number of devices, Price per device, Insurance";
        }
      } else if (this.get('status.isDemoDone')) {
        if (!this.get('expectedDevices') || !(this.get('probability') > 0) || !this.get('expectedDate')) {
          return "Cannot calculate without: Expected month, Number of devices, Probability";
        }
      }

      return null;
    }),
    missingMrrWeigthedFields: Ember.computed('missingMrrFields', function () {
      if (this.get('missingMrrFields')) {
        return "Cannot calculate without 'MRR Total'";
      }

      if (!(this.get('probability') > 0)) {
        return "Cannot calculate weighted MRR without field 'Probability'";
      }

      return null;
    }),
    mrrTotal: Ember.computed('status.id', 'expectedDevices', 'pricePerDevice', 'insurance', 'langs', 'hcs', 'expectedDate', 'probability', function () {
      if (this.get('status.isOfferPhaseDone')) {
        var _this$getProperties3 = this.getProperties('expectedDevices', 'pricePerDevice', 'insurance', 'langs', 'hcs'),
            expectedDevices = _this$getProperties3.expectedDevices,
            pricePerDevice = _this$getProperties3.pricePerDevice,
            insurance = _this$getProperties3.insurance,
            langs = _this$getProperties3.langs,
            hcs = _this$getProperties3.hcs;

        if (expectedDevices && pricePerDevice && insurance) {
          return [expectedDevices * pricePerDevice, expectedDevices * (insurance === 'yes' ? 29 : insurance === 'both' ? 14.50 : 0), expectedDevices * (langs || 0) * 10, (hcs === 'none' ? 0 : hcs || 0) / 12].reduce(function (sum, n) {
            return sum + n;
          }, 0);
        }
      } else if (this.get('status.isDemoDone') && !!this.get('expectedDevices') && !!this.get('probability') && !!this.get('expectedDate')) {
        return (this.get('expectedDevices') || 0) * 185;
      }

      return 0;
    }),
    mrrWeighted: Ember.computed('mrrTotal', 'probability', function () {
      var _this$getProperties4 = this.getProperties('mrrTotal', 'probability'),
          mrrTotal = _this$getProperties4.mrrTotal,
          probability = _this$getProperties4.probability;

      if (probability > 0) {
        return mrrTotal * (probability / 100);
      }

      return mrrTotal;
    }),
    mrrTotalFmt: Ember.computed('mrrTotal', function () {
      return (0, _formatCurrency.formatCurrency)([this.get('mrrTotal')]) + ' MRR total';
    }),
    mrrWeightedFmt: Ember.computed('mrrWeighted', function () {
      return (0, _formatCurrency.formatCurrency)([this.get('mrrWeighted')]) + ' MRR weighted';
    }),
    pipelineCategory: Ember.computed('status.id', 'probability', 'expectedDevices', 'expectedDate', function () {
      return !!this.get('expectedDevices') && !!this.get('probability') && !!this.get('expectedDate') ? 'pipeline-project' : 'uncategorized';
    }),
    client: belongsTo('client', {
      async: true
    }),
    lead: belongsTo('lead', {
      async: true
    }),
    sponsoredClient: belongsTo('sponsored-client', {
      async: true
    }),
    // Computed
    displayName: Ember.computed.reads('name'),
    nameWithOwner: Ember.computed('client.displayName', 'lead.displayName', 'name', function () {
      var owner = this.get('lead.displayName') || this.get('client.displayName');
      var department = this.get('name');
      return "".concat(owner, " (").concat(department, ")");
    }),
    testingOverdueDays: Ember.computed('trialEndDate', function () {
      var date = this.get('trialEndDate');

      if (date) {
        return _moment.default.duration((0, _moment.default)(date).diff((0, _moment.default)())).asDays().toFixed(1);
      }

      return 0;
    }),
    validators: {
      name: (0, _emberCpValidations.validator)('presence', true),
      status: (0, _emberCpValidations.validator)('presence', true),
      nextStep: (0, _emberCpValidations.validator)('department-is-gte-trial-done'),
      probability: (0, _emberCpValidations.validator)('department-is-offer-phase-done'),
      // Past dates have been cleared - skip validation for now to allow saving those leads/clients
      // expectedDate: validator('department-is-offer-phase-done'),
      expectedDevices: (0, _emberCpValidations.validator)('department-is-offer-phase-done')
    }
  });

  _exports.default = _default;
});